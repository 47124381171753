import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productId: "",
  productDetail: {},
};

export const productDetailSlice = createSlice({
  name: "productDetailReducer",
  initialState,
  reducers: {
    setProductDetailsData: (state, action) => {
      state.productDetail = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
  },
});
export const { setProductId, setProductDetailsData } =
  productDetailSlice.actions;

export default productDetailSlice.reducer;
