import React from "react";
import Categories from "../containers/productContainers/Categories";
import Breadcrums from "../components/BreadCrums";

const Products = () => {
  return (
    <>
      <Breadcrums />
      <Categories />
    </>
  );
};

export default Products;
