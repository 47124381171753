import React from "react";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa";

const AnyReactComponent = () => (
  <FaMapMarkerAlt className="h-12 w-8 text-primary" />
);

export default function Map(props) {
  const { center, zoom } = props;

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAF6YsJOwih_sVZfcIYeXzEDoHce60bUno" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} />
      </GoogleMapReact> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13249.53281730574!2d151.2099244!3d-33.8797834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12af0311bdfaa3%3A0xff932653447da876!2sEmprest%20Leather!5e0!3m2!1sen!2sin!4v1721828574371!5m2!1sen!2sin"
        width="100%"
        height="450"
        // style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
