import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Urls } from "../../../utills/constant";
import useApi from "../apiCall/useApi";
import { setCategoryData } from "../../reducers/category.reducer";
const useCategoryDetails = () => {
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const fetchCategoryData = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getCategoryDetails(),
        data: {},
      });
      dispatch(setCategoryData(data));
      return data;
    } catch (error) {
      console.log("categoryDataErr", error);
    }
  });

  return {
    fetchCategoryData,
  };
};
export default useCategoryDetails;
