import React from "react";
import ProductDetails from "../containers/productContainers/ProductDetails";
import RelatedProducts from "../containers/productContainers/RelatedProducts";
import Breadcrums from "../components/BreadCrums";

const ProductDetailsPage = () => {
  return (
    <>
      <Breadcrums style={{ background: "#E9EEF5" }} />
      <ProductDetails />
      <RelatedProducts />
    </>
  );
};

export default ProductDetailsPage;
