import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Link, useLocation } from "react-router-dom";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Breadcrums({ style }) {
  const location = useLocation();
  const breadcrumbs = [
    <Link
      id={location.pathname === "/products" ? "active" : ""}
      underline="hover"
      key="1"
      color="inherit"
      to="/products"
    >
      Products
    </Link>,
    <Link
      id={location.pathname === "/product-list" ? "active" : ""}
      underline="hover"
      key="2"
      color="inherit"
      to="/product-list"
    >
      Product List
    </Link>,
    <Link
      id={location.pathname === "/product-details" ? "active" : ""}
      underline="hover"
      key="2"
      color="inherit"
      to="/product-details"
    >
      Product Details
    </Link>,
    // <Typography key="3" color="text.primary">
    //   Breadcrumb
    // </Typography>,
  ];

  return (
    <Stack style={style} spacing={2} pt={2} pl={4}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
