import React, { useEffect } from "react";
import Card from "../../components/Card";
import Bags from "../../assets/Images/bags.jpg";
import MensWallet from "../../assets/Images/mens_wallet.jpg";
import LadiesWallet from "../../assets/Images/ladies_wallets.jpg";
import Accessories from "../../assets/Images/accessories.jpg";
import Jackets from "../../assets/Images/jackets.jpg";
import usecategotyDetails from "../../data/talons/Category/useCategoryDetails";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryData } from "../../data/selectors/category.selector";
import { useNavigate } from "react-router-dom";
import {
  setCategoryId,
  setProductName,
} from "../../data/reducers/product.reducer";
import Breadcrums from "../../components/BreadCrums";

const Categories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchCategoryData } = usecategotyDetails();
  const categorySelector = useSelector(getCategoryData);
  // const categories = [
  //   {
  //     id: 1,
  //     title: "MENS WALLET",
  //     image: MensWallet,
  //   },
  //   {
  //     id: 2,
  //     title: "LADIES WALLETS & BAGS",
  //     image: LadiesWallet,
  //   },
  //   {
  //     id: 3,
  //     title: "BAGS",
  //     image: Bags,
  //   },
  //   {
  //     id: 4,
  //     title: "ACCESSORIES",
  //     image: Accessories,
  //   },
  //   {
  //     id: 5,
  //     title: "JACKETS",
  //     image: Jackets,
  //   },
  // ];

  useEffect(() => {
    fetchCategoryData();
  }, []);

  return (
    <>
      <section className="relative flex flex-col justify-center items-center gap-12 w-full px-4 md:px-12 pt-20 pb-7">
        <h1 className="text-4xl lg:text-5xl font-light">Product Categories</h1>
        <ul className="flex flex-wrap justify-center items-center gap-4 w-full">
          {categorySelector.map((category) => {
            return (
              <Card
                key={category.id}
                title={category.category_name}
                image={
                  category.image_name !== null
                    ? category.image_name
                    : require("../../assets/Images/bags.jpg")
                }
                // image={require("../../assets/Images/bags.jpg")}
                handleClick={(e) => {
                  dispatch(setCategoryId(category.id));
                  dispatch(setProductName(category.category_name));
                  e.preventDefault();
                  navigate("/product-list");
                }}
              />
            );
          })}
        </ul>
      </section>
    </>
  );
};

export default Categories;
