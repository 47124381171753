import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProductId } from "../data/reducers/productDetails.reducer";
import useProductDetails from "../data/talons/Product/useProduct";
import { getProductId } from "../data/selectors/productDetail.selector";

const CustomDots = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;

  return (
    <button
      className={
        active
          ? "bg-primary w-3 h-3 mx-2 opacity-100 rounded-full"
          : "bg-primary opacity-20 w-3 h-3 mx-2 rounded-full"
      }
      onClick={() => onClick()}
    ></button>
  );
};

const ProductsCarousel = ({ products }) => {
  const { fetchReletedProductData } = useProductDetails();
  const dispatch = useDispatch();

  const id = useSelector(getProductId);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  useEffect(() => {
    fetchReletedProductData();
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="w-full flex justify-center items-center">
      <Carousel
        className="flex w-full pb-12 "
        additionalTransfrom={0}
        arrows={false}
        customDot={<CustomDots />}
        containerCl
        ass=""
        keyBoardControl
        minimumTouchDrag={80}
        showDots={true}
        slidesToSlide={1}
        swipeable
        responsive={responsive}
      >
        {products?.map((product) => {
          return (
            <li
              style={{ marginRight: 10 }}
              key={product.id}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setProductId(product.id));
              }}
              className={`slider flex flex-col justify-center rounded-md overflow-hidden bg-white border-textLight  border-[1px] md:w-[70%] cursor-pointer`}
            >
              <img
                src={product.image_url}
                alt="category image"
                className="bg-white"
              />
              <div className="p-4 h-13">
                <p className="text-xl font-thin">{product.name}</p>
              </div>
            </li>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductsCarousel;
