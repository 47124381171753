import React from "react";
import AboutBanner from "../containers/aboutPageContainers/AboutBanner";
import Services from "../containers/aboutPageContainers/Services";

const About = () => {
  return (
    <>
      <AboutBanner />
      <Services />
      {/* <Brands /> */}
    </>
  );
};

export default About;
