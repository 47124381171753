import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex w-full justify-between absolute left-0 right-0 px-4">
      <button onClick={() => previous()} className="z-50">
        <FaChevronLeft className="h-14 w-8 text-primary opacity-50 hover:opacity-100" />
      </button>
      <button
        onClick={() => next()}
        className="z-50 text-primary opacity-50 hover:opacity-100"
      >
        <FaChevronRight className="h-14 w-8" />
      </button>
    </div>
  );
};

const CustomDots = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button
      className={
        active
          ? "bg-primary w-8 h-1 mx-1 opacity-100"
          : "bg-primary opacity-20 w-8 h-1 mx-1"
      }
      onClick={() => onClick()}
    ></button>
  );
};

const HomeCarousal = (props) => {
  const { bannerData } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="w-full flex justify-center items-center flex-1 pb-16">
      <Carousel
        className="flex w-full max-w-screen-2xl"
        additionalTransfrom={0}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        containerClass=""
        keyBoardControl
        minimumTouchDrag={80}
        autoPlay
        infinite
        autoPlaySpeed={3000}
        showDots={true}
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customDot={<CustomDots />}
      >
        {bannerData.map((item, id) => {
          return (
            <div
              key={id}
              className="flex w-full px-14 md:px-24 pt-12 items-center gap-8 flex-col-reverse md:flex-row"
            >
              <div className="flex-[1.2] flex flex-col gap-2">
                <h1
                  className={`${
                    id > 0 ? "text-bgDark1 font-light" : "text-primary"
                  } text-5xl`}
                >
                  {item.title}
                </h1>
                <h2 className="text-3xl font-light">{item.subTitle}</h2>
                <p className="text-lg font-light">{item.description}</p>
              </div>
              <div className="flex-[0.6] w-full">
                <img
                  src={item.image}
                  alt="baanner image"
                  className="object-contain"
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HomeCarousal;
