import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: [],
};

export const categorySlice = createSlice({
  name: "categoryReducer",
  initialState,
  reducers: {
    setCategoryData: (state, action) => {
      state.category = action.payload.data;
    },

    
  },
});

export const { setCategoryData } = categorySlice.actions;

export default categorySlice.reducer;
