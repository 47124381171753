import React, { useEffect } from "react";
import Card from "../../components/Card";
import Bags from "../../assets/Images/bags.jpg";
import MensWallet from "../../assets/Images/mens_wallet.jpg";
import LadiesWallet from "../../assets/Images/ladies_wallets.jpg";
import Accessories from "../../assets/Images/accessories.jpg";
import Jackets from "../../assets/Images/jackets.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getProductData,
  getProductName,
} from "../../data/selectors/product.selector";
import useProductDetails from "../../data/talons/Product/useProduct";
import { useDispatch } from "react-redux";
import { setProductId } from "../../data/reducers/productDetails.reducer";
import Breadcrums from "../../components/BreadCrums";
const ProductList = () => {
  const navigate = useNavigate();
  const productSelector = useSelector(getProductData);
  const { fetchProductData } = useProductDetails();
  const dispatch = useDispatch();
  const ProductName = useSelector(getProductName);
  useEffect(() => {
    fetchProductData();
  }, [ProductName]);

  return (
    <>
      <Breadcrums />
      <section className="relative flex flex-col justify-center items-center text-center gap-12 w-full px-4 md:px-12 py-14">
        <h1 className="text-4xl lg:text-5xl font-light">{ProductName}</h1>
        <ul className="flex flex-wrap justify-center items-center gap-4 w-full">
          {productSelector?.map((product) => {
            return (
              <Card
                key={product.id}
                title={product.name}
                image={
                  product.image_url === null
                    ? require("../../assets/Images/dummy.jpg")
                    : product.image_url
                }
                // image={
                //   product.image_name !== null
                //     ? require(product.image_name)
                //     : require("../../assets/Images/bags.jpg")
                // }
                // image = {require("../../assets/Images/category_img_03.jpg")}
                handleClick={(e) => {
                  e.preventDefault();
                  dispatch(setProductId(product.id));
                  navigate("/product-details");
                }}
              />
            );
          })}
        </ul>
      </section>
    </>
  );
};

export default ProductList;
