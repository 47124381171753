import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import ImageZoom from "react-image-zooom";
import dummy from "../assets/Images/dummy.jpg";
import { size } from "lodash";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex w-full justify-between absolute left-0 right-0 px-4">
      <button onClick={() => previous()} className="z-50 text-black">
        <FaChevronLeft className="h-4" />
      </button>
      <button onClick={() => next()} className="z-50 text-black">
        <FaChevronRight className="h-4" />
      </button>
    </div>
  );
};

const ProductImageGallery = ({ images }) => {
  let masterImage;
  images?.map((img) => {
    if (img.is_master === 1) {
      masterImage = img.url;
    }
  });

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!masterImage) {
      setSelectedImage(dummy);
    } else {
      setSelectedImage(masterImage);
    }
  }, [images]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  return (
    <div className="flex flex-col gap-8">
      <ImageZoom
        src={
          selectedImage ? selectedImage : require("../assets/Images/dummy.jpg")
        }
        alt="A image to apply the ImageZoom plugin"
        zoom="200"
      />
      {/* <img
        src={selectedImage}
        alt="Product Image"
        className="h-full self-center w-full flex-1 object-cover border-[1px] border-borderGrey rounded-md"
      /> */}
      <div className="w-full flex justify-center items-center md:pb-16 relative pl-14 pr-10">
        {size(images) > 0 && (
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            containerClass=""
            keyBoardControl
            infinite
            autoPlay
            autoPlaySpeed={5000}
            minimumTouchDrag={80}
            className="flex w-20 flex-1 gap-5"
            customButtonGroup={<ButtonGroup />}
            showDots={false}
            responsive={responsive}
            renderButtonGroupOutside
          >
            {images?.map((item) => {
              return (
                <button
                  style={{
                    // border: "1px solid black",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => setSelectedImage(item.url)}
                >
                  <img
                    style={{ objectFit: "cover", width: "85%", height: "100%" }}
                    src={item.url}
                    alt="Product Image"
                    className="h-22 md:h-32 lg:h-28 self-center w-[90%] object-cover border-[1px] border-borderGrey rounded-md cursor-pointer"
                  />
                </button>
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default ProductImageGallery;
