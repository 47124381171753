import React from "react";
import Banner from "../../assets/Images/about-hero.svg";

const AboutBanner = () => {
  return (
    <section className="bg-primary relative flex justify-center items-center w-full py-12">
      <div className="max-w-screen-2xl w-full px-4 md:px-12 flex justify-center items-center flex-col md:flex-row">
        <div className="flex-1 flex flex-col gap-3">
          <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-medium">
            About Us
          </h1>
          <p className="text-white text-lg font-light max-w-4xl">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="flex-1 w-full md:flex-[0.5]">
          <img src={Banner} alt="About Banner" className="object-contain" />
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
