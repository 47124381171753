import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Urls } from "../../../utills/constant";
import useApi from "../apiCall/useApi";

import {
  setProductData,
  setReletedProduct,
  setReletedProductData,
} from "../../reducers/product.reducer";
import { getCategoryId } from "../../selectors/product.selector";
import {
  getProductId,
  getSelecetdProduct,
} from "../../selectors/productDetail.selector";
const useProductDetails = () => {
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const categoryIdSelector = useSelector(getCategoryId);
  const id = useSelector(getProductId);
  const fetchProductData = useCallback(async () => {
    //product list
    try {
      const { data } = await apiPost({
        url: Urls.getReletedProduct(),
        data: { category_id: categoryIdSelector, status: 1 },
      });

      dispatch(setProductData(data.recordset));
      return data;
    } catch (error) {
      console.log("productDataErr", error);
    }
  });

  const fetchReletedProductData = useCallback(async () => {
    //product details
    try {
      const { data } = await apiPost({
        url: Urls.gerProductDetails(),
        data: { id: id, category_id: categoryIdSelector, status: 1 },
      });
      dispatch(setReletedProduct(data?.data[0]));
      return data;
    } catch (error) {
      console.log("reletdProduct==>", error);
    }
  });

  const fetchReletedMasterProduct = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getMasterRelatedProduct(),
        data: { id: id },
      });
      dispatch(setReletedProductData(data?.recordset));
      return data;
    } catch (error) {
      console.log("reletdMasterProduct==>", error);
    }
  });

  return {
    fetchProductData,
    fetchReletedProductData,
    fetchReletedMasterProduct,
  };
};
export default useProductDetails;
