import { useCallback } from "react";
// import { useDispatch } from "react-redux";
import { Urls } from "../../../utills/constant";
import useApi from "../apiCall/useApi";
const useContact = () => {
  const { apiPost } = useApi();
  //   const dispatch = useDispatch();
  const insertContactData = useCallback(async (contact) => {
    try {
      const { data } = await apiPost({
        url: Urls.insertContactDetails(),
        data: contact,
      });
      return data;
    } catch (error) {
      console.log("contactErr", error);
    }
  });

  return {
    insertContactData,
  };
};
export default useContact;
