import React from "react";
import ContactBanner from "../containers/contactContainers/ContactBanner";
import Map from "../containers/contactContainers/Map";
import ContactForm from "../containers/contactContainers/ContactForm";

const Contact = () => {
  return (
    <>
      <ContactBanner />
      <Map
        center={{ lat: -33.879676487038395, lng: 151.20992439611214}}
        zoom={17}
      />
      <ContactForm />
    </>
  );
};

export default Contact;
