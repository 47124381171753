import React from "react";

const Button = (props) => {
  const { text, className, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`bg-primary text-white px-4 py-2 rounded-md text-lg font-light ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
