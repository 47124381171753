import React from "react";

const WarrantyPolicy = () => {
  return (
    <section className="flex justify-center items-center w-full py-20">
      <div className="max-w-screen-2xl w-full flex flex-col gap-12 px-4 md:px-12 xl:px-24">
        <div className="flex flex-col justify-center items-center gap-8">
          <h2 className="text-5xl font-extralight text-center">
            Terms and conditions of warranty
          </h2>

          <p className="text-lg font-light text-left">
            <span className="font-semibold ">
              Your experience is our reward!
            </span>
            <br /> <br />
            Our 1 year WARRANTY underpins our confidence in our products. <br />
            <br /> In the unlikely event that there is a problem with the
            leather or workmanship then we will endeavour to replace the product
            concerned under the terms of the warranty with either the same
            product or, if the product is no longer in our current stock, we
            will replace it with a product closely matching the original. <br />
            <br /> Our warranty covers against defects in LEATHER or WORKMANSHIP
            for ONE YEAR from the date of purchase. A valid warranty card will
            be required for replacement of product. <br /> <br /> Warranty is
            void if the leather product has been damaged by accident,
            negligence, unauthorized service, or other factors not due to
            defects in leather or workmanship, or a valid warranty card is not
            provided.
            <br />
            <br />
            Your leather product will be replaced within 30 days of the date
            when we receive the damaged product.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WarrantyPolicy;
