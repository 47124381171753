import React from "react";

const Card = (props) => {
  const { image, title, handleClick } = props;

  return (
    <li
      onClick={handleClick}
      className="flex flex-col gap-2 justify-center rounded-md overflow-hidden bg-white border-textLight border-[1px] w-full md:w-[30%] xl:w-[20%] cursor-pointer"
    >
      <img src={image} alt="category image" className="h-60 bg-white" />
      <div className="p-4 h-24">
        <p className="text-xl font-thin">{title}</p>
      </div>
    </li>
  );
};

export default Card;
