import { useCallback } from "react";
import Api from "../../../utills/api.utills";
// import { useSelector } from "react-redux";
// import { getTokenId } from "../../selectors/appState.selector";

function useApi() {
  const tokenId =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMjkxMEQxMzctN0QyMC00RUU5LUFGN0MtNTg4RDQyM0U0OENCIiwiZGJfbmFtZSI6IkNSTV8wMDAwMCIsImlhdCI6MTcxOTQxMzc2MywiZXhwIjoxNzUwOTQ5NzYzfQ.l_JcSk1N4ZF-qAL6YhgVhGxxe3JFdVndrDjobumJHxA";
  const apiPost = useCallback(
    async (config) => {
      config = { ...config, header: `Bearer ` + tokenId };
      let mResponse = [];
      try {
        mResponse = await Api.post({
          ...config,
        });
      } catch (err) {
        console.log("Post Error :- " + err);
      } finally {
        return mResponse;
      }
    },
    [tokenId]
  );

  return {
    apiPost,
  };
}
export default useApi;
