import React from "react";
import Banner1 from "../../assets/Images/EMP_004_HNT_01.png";
import Banner2 from "../../assets/Images/EMP_BG_BLK_041.png";
import Banner3 from "../../assets/Images/EMP_SKP_1_001.png";
import Banner4 from "../../assets/Images/EMP_DF_2_HNT_001.png";
import Banner5 from "../../assets/Images/FG_2001_002.png";
import HomeCarousal from "../../components/HomeCarousal";

const HomeBanner = () => {
  const bannerData = [
    {
      id: 1,
      title: "Emprest Leather",
      subTitle: "Leather Wallets for Men",
      description:
        "Discover our stylish and durable leather wallets for men, designed for elegance and longevity perfect for every gentleman's daily use.",
      image: Banner1,
    },
    {
      id: 2,
      title: "Leather Bag",
      subTitle: " Cross Body Travel Messenger, One Side Shoulder Handbag",
      description:
        "Discover our stylish and durable leather bags, perfect for everyday use and carrying all your essentials",
      image: Banner2,
    },
    {
      id: 3,
      title: "Hand Bag for Women",
      subTitle: " Fashion Handbags Top Handle Satchel Purse for Ladies",
      description:
        "Explore our elegant and durable leather purses for ladies, designed to combine style and functionality for everyday use.",
      image: Banner3,
    },
    {
      id: 4,
      title: "Duffle Bag",
      subTitle: "Leather Duffel Bag with Extra Additional Pocket for Travel",
      description:
        "Check out our stylish leather duffel bags featuring extra pockets, perfect for organized and durable travel, ensuring both style and functionality.",
      image: Banner4,
    },
    {
      id: 5,
      title: "Leather Belt",
      subTitle: " Belt for Men Classic Designs for Work & Business Casual",
      description:
        "Explore our classic men's belts, crafted for work and business casual attire, combining timeless design with versatility and reliability for any occasion.",
      image: Banner5,
    },
  ];

  return (
    <section className="bg-bgOffWhite flex w-full h-full relative">
      <div className="flex w-full justify-center items-center">
        <HomeCarousal bannerData={bannerData} />
      </div>
    </section>
  );
};

export default HomeBanner;
