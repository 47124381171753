import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: [],
  categoryId: "",
  name: "",
  reletedProduct: {},
  reletedProductData: [],
};

export const productSlice = createSlice({
  name: "categoryReducer",
  initialState,
  reducers: {
    setProductData: (state, action) => {
      state.product = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setProductName: (state, action) => {
      state.name = action.payload;
    },
    setReletedProduct: (state, action) => {
      state.reletedProduct = action.payload;
    },
    setReletedProductData: (state, action) => {
      state.reletedProductData = action.payload;
    },
  },
});

export const {
  setProductData,
  setCategoryId,
  setProductName,
  setReletedProduct,
  setReletedProductData,
} = productSlice.actions;

export default productSlice.reducer;
