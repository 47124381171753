import React, { useEffect } from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import Logo from "../assets/Images/emprest_logo.png";
import { Link } from "react-router-dom";
import { getCategoryData } from "../data/selectors/category.selector";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryId,
  setProductName,
} from "../data/reducers/product.reducer";

import { getProductName } from "../data/selectors/product.selector";
import useProductDetails from "../data/talons/Product/useProduct";

const Footer = () => {
  const { fetchProductData } = useProductDetails();
  const categorySelector = useSelector(getCategoryData);

  const ProductName = useSelector(getProductName);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchProductData();
    window.scrollTo(0, 0);
  }, [ProductName]);
  return (
    <footer className="flex justify-center items-center flex-col bg-bgDark1 text-textLight">
      <div className="max-w-screen-2xl w-full flex flex-col md:flex-row gap-8 items-start px-4 md:px-12 pt-6 pb-5">
        <div className="w-full md:flex-[1.3]">
          <h2 className="pb-4 border-b-[1.5px] border-border text-3xl font-light mt-5 lg:mt-[2.1rem]">
            Products
          </h2>
          <ul className="pt-4 flex gap-14">
            <li>
              {categorySelector.slice(0, 3).map((category) => {
                return (
                  <li style={{ marginTop: 15 }} key={category.id}>
                    <Link
                      onClick={() => {
                        dispatch(setCategoryId(category.id));
                        dispatch(setProductName(category.category_name));
                      }}
                      to="/product-list"
                      className="text-lg font-light hover:text-primaryLight"
                    >
                      {category.category_name}
                    </Link>
                  </li>
                );
              })}
            </li>
            <li>
              {categorySelector.slice(3).map((category) => {
                return (
                  <li style={{ marginTop: 15 }} key={category.id}>
                    <Link
                      onClick={() => {
                        dispatch(setCategoryId(category.id));
                        dispatch(setProductName(category.category_name));
                      }}
                      to="/product-list"
                      className="text-lg font-light hover:text-primaryLight"
                    >
                      {category.category_name}
                    </Link>
                  </li>
                );
              })}
            </li>
          </ul>
        </div>

        <div className="w-full md:flex-[0.8]">
          <h2 className="pb-4 border-b-[1.5px] border-border text-3xl font-light mt-5 lg:mt-[2.1rem]">
            Further Info
          </h2>
          <ul className="pt-4 flex gap-10">
            <li>
              <li style={{ marginTop: 15 }}>
                <Link
                  to="/"
                  className="text-lg font-light hover:text-primaryLight"
                >
                  Home
                </Link>
              </li>
              <li style={{ marginTop: 15 }}>
                <Link
                  to="/about"
                  className="text-lg font-light hover:text-primaryLight"
                >
                  About Us
                </Link>
              </li>
              <li style={{ marginTop: 15 }}>
                <Link
                  to="/products"
                  className="text-lg font-light hover:text-primaryLight"
                >
                  Products
                </Link>
              </li>
            </li>

            <li>
              <li style={{ marginTop: 15 }}>
                <Link
                  to="/contact"
                  className="text-lg font-light hover:text-primaryLight"
                >
                  Contact
                </Link>
              </li>
              <li style={{ marginTop: 15 }}>
                <Link
                  to="/warranty-policy"
                  className="text-lg font-light hover:text-primaryLight"
                >
                  Warranty Policy
                </Link>
              </li>
            </li>
          </ul>
        </div>
        <div className="flex w-full md:flex-[1.5] lg:flex-1 flex-col">
          <div className="pb-4 border-b-[1.5px] border-border">
            <img src={Logo} className="object-contain w-56 lg:w-72" />
          </div>

          <ul className="flex flex-col justify-center pt-4 gap-2">
            {/* <li className="flex items-center gap-2 text-lg font-light">
              <FaMapMarkerAlt />
              123 Consectetur at ligula 10660
            </li> */}
            <li className="flex items-center gap-2 text-lg font-light">
              <FaPhone />
              <Link to="tel:+614 2522 4665" className="hover:text-primaryLight">
                +61 425 224 665
              </Link>
            </li>
            <li className="flex items-center gap-2 text-lg font-light">
              <FaEnvelope />
              <Link
                to="mailto:info@emprest.com.au"
                className="hover:text-primaryLight"
              >
                info@emprest.com.au
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="flex flex-col max-w-screen-2xl w-full px-4 md:px-12 gap-4">
        <div className="bg-border w-full h-[1.5px]" />
        <div className="flex flex-wrap justify-between items-center pt-6 pb-10 gap-6">
          <div className="flex">
            <ul className="flex gap-4">
              <li>
                <Link
                  target="_blank"
                  to="http://facebook.com/"
                  className="py-3 px-4 border-2 rounded-full border-border flex justify-center items-center hover:bg-textLight hover:text-border"
                >
                  <FaFacebookF className="h-6" />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/"
                  className="p-3 border-2 rounded-full border-border flex justify-center items-center hover:bg-textLight hover:text-border"
                >
                  <FaInstagram className="h-6 w-6" />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://twitter.com/"
                  className="p-3 border-2 rounded-full border-border flex justify-center items-center hover:bg-textLight hover:text-border"
                >
                  <FaTwitter className="h-6 w-6" />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/"
                  className="p-3 border-2 rounded-full border-border flex justify-center items-center hover:bg-textLight hover:text-border"
                >
                  <FaLinkedin className="h-6 w-6" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex rounded-sm border-[1.5px] border-border">
            <input
              type="text"
              id="subscribeEmail"
              placeholder="Email address"
              className="bg-transparent py-2 px-2 md:px-3 placeholder:text-placeholder text-sm md:text-base"
            />
            <div className="bg-primary text-textWhite py-2 px-2 md:px-3 rounded-r-sm text-sm md:text-base">
              Subscribe
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex justify-center items-center bg-bgDark2 py-2 w-full">
        <p className="text-lg font-light max-w-screen-2xl w-full px-4 md:px-12">
          Copyright &copy; 2024 Emprest Leather{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
