import React from "react";

const ContactBanner = () => {
  return (
    <section className="relative flex w-full justify-center items-center bg-bgLight py-10">
      <div className="flex flex-col justify-center items-center gap-4 max-w-screen-2xl w-full px-4 md:px-12">
        <h1 className="text-5xl font-extralight text-center">Contact Us</h1>
        {/* <p className="text-lg max-w-md xl:max-w-xl text-center">
          Proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum. Lorem ipsum dolor sit amet.
        </p> */}
      </div>
    </section>
  );
};

export default ContactBanner;
