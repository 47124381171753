import React, { useEffect, useRef, useState } from "react";
import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaPhone,
  FaEnvelope,
  FaBars,
  FaLinkedin,
} from "react-icons/fa";
import Logo from "../assets/Images/emprest_logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const navigate = useNavigate();
  // function stickNavbar() {
  //   let windowHeight = window.scrollY;
  //   windowHeight > 5 ? setStickyClass(true) : setStickyClass(false);
  // }

  const location = useLocation();
  const menuRef = useRef(null);
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenToggle(false);
      }
    };

    if (openToggle) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openToggle]);

  return (
    <div className="fixed flex flex-col w-full top-0 z-50 shadow-lg">
      <div className="hidden md:flex justify-center items-center bg-bgDark1 text-textWhite py-2">
        <div className="max-w-screen-2xl w-full flex justify-between items-center px-4 md:px-12">
          <div className="flex gap-4">
            <Link
              href="mailto:info@emprest.com.au"
              className="flex gap-2 items-center"
            >
              <FaEnvelope className="h-3 w-3" />
              <span className="text-xs">info@emprest.com.au</span>
            </Link>
            <Link to="tel:+614 2522 4665" className="flex gap-2 items-center">
              <FaPhone className="h-3 w-3" />
              <span className="text-xs">+61 425 224 665</span>
            </Link>
          </div>
          <div className="flex items-center gap-3">
            <Link to="/warranty-policy" className="text-xs">
              Warranty Policy
            </Link>
            <Link to="#">
              <FaFacebookF className="h-3 w-3" />
            </Link>
            <Link to="#">
              <FaInstagram className="h-3 w-3" />
            </Link>
            <Link to="#">
              <FaTwitter className="h-3 w-3" />
            </Link>
            <Link to="#">
              <FaLinkedin className="h-3 w-3" />
            </Link>
          </div>
        </div>
      </div>
      <nav
        className={`bg-white flex flex-col justify-center items-center py-4 z-10`}
      >
        <div className="relative max-w-screen-2xl w-full flex justify-between items-center px-4 md:px-12">
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={Logo}
            className="object-contain h-12 lg:h-14"
          />
          <button
            className="flex md:hidden"
            onClick={() => setOpenToggle(!openToggle)}
          >
            <FaBars />
          </button>
          <ul className="hidden md:flex gap-12 lg:gap-16 ">
            <li className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light">
              <Link id={location.pathname === "/" ? "active" : ""} to="/">
                Home
              </Link>
            </li>
            <li className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light">
              <Link
                id={location.pathname === "/about" ? "active" : ""}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light">
              <Link
                id={
                  location.pathname === "/products" ||
                  location.pathname === "/product-list" ||
                  location.pathname === "/product-details"
                    ? "active"
                    : ""
                }
                to="/products"
              >
                Products
              </Link>
            </li>
            <li className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light">
              <Link
                id={location.pathname === "/contact" ? "active" : ""}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <ul
          ref={menuRef}
          className={`${
            openToggle ? "flex" : "hidden"
          } md:hidden flex-col gap-6 lg:gap-16 self-start px-4 pt-6 pb-4`}
        >
          <li
            onClick={() => setOpenToggle(!openToggle)}
            className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light"
          >
            <Link to="/">Home</Link>
          </li>
          <li
            onClick={() => setOpenToggle(!openToggle)}
            className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light"
          >
            <Link to="/about">About</Link>
          </li>
          <li
            onClick={() => setOpenToggle(!openToggle)}
            className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light"
          >
            <Link to="/products">Products</Link>
          </li>
          <li
            onClick={() => setOpenToggle(!openToggle)}
            className="hover:text-primaryLight text-bgDark1 text-base lg:text-lg font-light"
          >
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
