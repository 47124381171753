import React from "react";
import { FaTruck, FaExchangeAlt, FaPercent, FaUser } from "react-icons/fa";
import ServiceCard from "../../components/ServiceCard";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "GENUINE BUFF",
      icon: <FaTruck className="h-24 w-20" />,
    },
    {
      id: 2,
      title: "COW",
      icon: <FaExchangeAlt className="h-24 w-12" />,
    },
    {
      id: 3,
      title: "GOAT ",
      icon: <FaPercent className="h-24 w-10" />,
    },
    {
      id: 4,
      title: "LAMB LEATHER",
      icon: <FaUser className="h-24 w-10" />,
    },
  ];
  return (
    <section className="flex justify-center items-center w-full py-20">
      <div className="max-w-screen-2xl w-full flex flex-col gap-12 px-4 md:px-12 xl:px-24">
        <div className="flex flex-col justify-center items-center gap-4">
          <h2 className="text-5xl font-extralight text-center">About Us</h2>
          <p className="text-lg font-light text-left">
            We are one of the most avant-garde producers and suppliers of
            quality GENUINE LEATHER GOODS. We specialize in designing and
            fabricating tailor-made products for our customers in Australia and
            the rest of the world. <br /> <br /> We are Australian Owned and
            operated company, so we are very transparent in our approach to the
            business and you can expect the personal attention and care for the
            orders that you place with us. We firmly believe that our customer
            focused approach and commitment to quality has helped us make the
            leap from customer satisfaction to customer delight. <br /> <br />{" "}
            We are the top leather goods Manufacturer promising to provide you
            with the finest quality products, always. From purses to wallets,
            handbags to backpacks, travel bags to card holders, key chain
            holders to Tobacco pouches & other fashion accessories. <br />{" "}
            <br /> Our skilled and experienced craftsmen cater to a motley of
            customers to offer top-class, flawless, and durable items just the
            way they desire them to be. Leather is a timeless thing. And, we
            strive to make it even better for you.
            <br /> <br />
            <span className="font-semibold">
              WE ALSO PROVIDE 1 YEAR WARRANTY ON ALL OUR PRODUCTS.
            </span>
          </p>
        </div>
        <p className="text-3xl font-extralight text-center">
          Our products are made of
        </p>
        <ul className="flex items-center justify-center flex-wrap gap-8">
          {services.map((service) => {
            return (
              <ServiceCard
                key={service.id}
                title={service.title}
                icon={service.icon}
              />
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Services;
