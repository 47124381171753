import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import ProductImageGallery from "../../components/ProductImageGallery";

import { getReletedProduct } from "../../data/selectors/product.selector";
import useProductDetails from "../../data/talons/Product/useProduct";

const ProductDetails = () => {
  const { fetchReletedProductData } = useProductDetails();
  const productSelector = useSelector(getReletedProduct);

  useEffect(() => {
    fetchReletedProductData();
  }, []);

  const productDetailPage = useMemo(() => {
    return (
      <section className="min-h-screen flex justify-center items-center w-full bg-bgLight">
        <div className="max-w-screen-2xl w-full flex gap-12 px-4 md:px-12 xl:px-24 py-20 flex-col lg:flex-row">
          <div className="flex-1 flex justify-center">
            <ProductImageGallery images={productSelector?.images} />
          </div>
          <div className="flex-[1.2] bg-white px-6 py-8 border-[1px] border-borderGrey rounded-md gap-6 flex flex-col">
            <div className="flex flex-col gap-2">
              <h1 className="text-3xl font-light">{productSelector?.name}</h1>
              {/* <h3 className="text-xl font-light">$ {productSelector?.mrp}</h3> */}
            </div>
            {/* <div className="flex gap-2 items-center">
                <p className="text-black font-semibold">Brand:</p>
                <p className="text-textGrey font-semibold text-lg">
                  {productSelector?.title}
                </p>
              </div> */}
            <div className="flex flex-col gap-2">
              <p className="text-black font-semibold">Description:</p>
              <p className="text-lg font-light">
                {productSelector?.description}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-black font-semibold">Avaliable Color:</p>
              <p className="text-textGrey font-semibold text-lg">
                White / Black
              </p>
            </div>
            {/* <div className="flex flex-col gap-2">
                <p className="text-black font-semibold">Specification:</p>
                <ul className="text-lg font-light">
                  <li>Lorem ipsum dolor sit</li>
                  <li>Amet, consectetur</li>
                  <li>Adipiscing elit,set</li>
                  <li>Duis aute irure</li>
                  <li>Ut enim ad minim</li>
                  <li>Dolore magna aliqua</li>
                  <li>Excepteur sint</li>
                </ul>
              </div> */}
            {/* <div className="flex gap-2 items-center font-light text-lg">
                <div className="">
                  <p>Size :</p>
                </div>
                <p>Quantity :{productSelector?.op_qty}</p>
              </div> */}
            {/* <div className="flex gap-6 flex-col md:flex-row">
                <Button text={"Buy"} className={"flex-1"} />
                <Button text={"Add To Cart"} className={"flex-1"} />
              </div> */}
          </div>
        </div>
      </section>
    );
  }, [productSelector]);

  return productDetailPage;
};

export default ProductDetails;
