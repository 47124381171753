import React, { useState } from "react";

const ServiceCard = (props) => {
  const { title, icon } = props;
  const [hover, setHover] = useState(false);

  return (
    <li
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="bg-white flex justify-center items-center gap-4 flex-col py-12 px-8 shadow-md w-full md:w-2/5 2xl:w-1/5 hover:bg-primary hover:text-white text-primary"
    >
      {icon}
      <p
        className={`text-xl font-medium ${
          hover ? "text-white" : "text-bgDark1"
        }`}
      >
        {title}
      </p>
    </li>
  );
};

export default ServiceCard;
