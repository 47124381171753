import React from "react";
import HomeBanner from "../containers/homePageContainers/HomeBanner";

import FeaturedProducts from "../containers/homePageContainers/FeaturedProducts";
import Categories from "../containers/productContainers/Categories";
import Breadcrums from "../components/BreadCrums";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <Categories />
      {/* <FeaturedProducts /> */}
    </>
  );
};

export default Home;
