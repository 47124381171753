import React, { useEffect } from "react";
import ProductsCarousel from "../../components/ProductsCarousel";
import Bags from "../../assets/Images/bags.jpg";
import MensWallet from "../../assets/Images/mens_wallet.jpg";
import LadiesWallet from "../../assets/Images/ladies_wallets.jpg";
import Accessories from "../../assets/Images/accessories.jpg";
import Jackets from "../../assets/Images/jackets.jpg";
import useProductDetails from "../../data/talons/Product/useProduct";

import { useSelector } from "react-redux";
import { getSelecetdProduct } from "../../data/selectors/productDetail.selector";
import { getReletedProductData } from "../../data/selectors/product.selector";

const RelatedProducts = () => {
  const reletedProductSelector = useSelector(getReletedProductData);
  const { fetchReletedMasterProduct } = useProductDetails();
  useEffect(() => {
    fetchReletedMasterProduct();
  }, []);
  return (
    <section className="flex w-full bg-white justify-center">
      <div className="max-w-screen-2xl w-full flex flex-col gap-12 px-4 md:px-12 xl:px-24 py-20">
        <h1 className="text-4xl font-medium text-center">Related Products</h1>

        {reletedProductSelector && (
          <ProductsCarousel products={reletedProductSelector} />
        )}
      </div>
    </section>
  );
};

export default RelatedProducts;
