import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import categoryReducer from "./data/reducers/category.reducer";
import productReducer from "./data/reducers/product.reducer";
import productDetailsReducer from "./data/reducers/productDetails.reducer";

const rootReducer = combineReducers({
  category: categoryReducer,
  product: productReducer,
  productDetail: productDetailsReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["product", "productDetail", "category"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());

export const persistor = persistStore(store);

export default store;
