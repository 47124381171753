import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Products from "./pages/Products";
import ProductList from "./containers/productContainers/ProductList";
import WarrantyPolicy from "./pages/WarrantyPolicy";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import { Provider } from "react-redux";
import store from "./store";
export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Navbar />
        <main className="min-h-screen h-full overflow-hidden flex flex-col justify-center mt-20 md:mt-28">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/product-details" element={<ProductDetailsPage />} />
            <Route path="/warranty-policy" element={<WarrantyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}
